import {action, computed, observable, runInAction} from 'mobx';
import firebase from 'firebase/app';
import LexiconService from '../services/LexiconService';
import {LexiconModel} from '../models/LexiconModel';

export default class LexiconStore {
  @observable isReady = false;
  dbListener = null;
  @observable _list = new observable.map();
  //@observable list = [];
  @observable user = null;

  @observable data = null;
  @observable status = "initial";
  @observable searchQuery = "";

  constructor(Stores) {
    this.stores = Stores;
    this.service = new LexiconService();
    this.model = LexiconModel;
  }

  get list() {
    if (!this.dbListener) {
      this.listenToDB();
    }
    console.log('list user', this.dbListener, this._list);
    return this._list;
  }

  @computed
  set list(item) {
    return this._list.set(item);
  }

  listenToDB = agencyId => {
    this.dbListener = firebase
      .firestore()
      .collection('lexicons')
      //.orderBy('createdAt', 'asc')
      .onSnapshot(snapshot => {
        runInAction(() => {
          this.status = 'listening';
          this.isReady = true;
          snapshot.docChanges().forEach(change => {
            const doc = change.doc.data(); // TODO add words filter
            doc.id = change.doc.id;
            if (change.type === 'added' || change.type === 'modified') {
              this.handleAdd(doc);
            }
            if (change.type === 'removed') {
              this.handleRemove(doc.id);
            }
          });
        });
      });
  };

  @action
  handleRemove(docId) {
    this._list.delete(docId);
  }

  @action
  handleAdd(doc) {
    const item = new this.model(doc);
    if (this._list.has(doc.id)) {
      if (JSON.stringify(item) !== JSON.stringify(this._list.get(doc.id)))
        this._list.set(doc.id, item);
    } else
      this._list.set(doc.id, item);
  }

//
// toJS() {
//   return this.todos.map(item => item.toJS());
// }
//
// static fromJS(array) {
//   const userStore = new UserStore();
//   userStore.list = array.map(item => this.model.fromJS(userStore, item));
//   return userStore;
// }

// @action setUserLocation = () => {
//   navigator.geolocation.getCurrentPosition(position => {
//     runInAction(() => {
//       this.userLocation.lat = position.coords.latitude;
//       this.userLocation.lng = position.coords.longitude;
//     });
//   });
// };
}
