import {inject, observer} from "mobx-react";
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {TagInput} from '../../components';

export const Keywords = inject('AuthStore', 'UserStore')(observer((props) => {
    const {handleSubmit} = useForm();

    const me = props.AuthStore.me;
    const [loading, setLoading] = useState(false);
    const [keywords, setKeywords] = useState(null);
    const [keywordError, setKeywordError] = useState(null);

    useEffect(() => {
        setKeywords((me.keywords || []));//.split(',').filter(i => i && i.length))
    }, [me]);

    const onSubmit = async (data) => {
        if (!keywords.length)
            return setKeywordError(`The keywords are required!`);
        if (keywords.length > 5)
            return setKeywordError(`Keywords should be max 5`);

        data.keywords = keywords;
        setLoading(true);
        if (await props.UserStore.updateKeywords(data)) {
            if (props.hasOwnProperty('onSuccess'))
                props.onSuccess(data);
        }
        setLoading(false);
    };


    return (
        <div className="row justify-content-md-center">
            <form className="col-md-6" onSubmit={handleSubmit(onSubmit)}>

                <div>
                    <fieldset className="form-group">
                        <label>Keywords</label>
                        <TagInput
                            name="keywords"
                            items={keywords}
                            onChange={(items) => setKeywords(items)}
                            error={keywordError}
                        />
                    </fieldset>
                </div>
                <div>
                    <button className="btn btn-primary" type="submit" disabled={loading}>
                        {loading && <span><i className="fa fa-circle-notch fa-spin"/>&nbsp;</span>}
                        Save my Keywords
                    </button>
                </div>
            </form>
        </div>
    )
}));
