import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {Input} from "reactstrap";

import DataTable from 'react-data-table-component';
import {InlineEdit,InlineEditRange} from 'components';


@inject('AuthStore', 'SetStore')
@observer
class List extends Component {

  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    const search = params.get('search') || '';
    const page = params.get('page') || 1;
    this.state = {
      me: props.me,
      count: 0,
      page,
      limit: 10,
      range: 10,
      items: [],
      query: search,
      search: search,
      filteredItems: false,
      system: false,

      id: props.match.params.id
    };

    // autorun(() => {
    //   props.SetStore.isReady()
    // })
  }

  handleFilter = (e) => {
    e.preventDefault();
    console.log(e.currentTarget.value);
    const query = e.currentTarget.value.toLowerCase();
    const filteredItems = this.state.items.filter(item => {
      console.log({item});
      return (item.name.toLowerCase().indexOf(query) > -1 || item.surname.toLowerCase().indexOf(query) > -1
        || item.username.toLowerCase().indexOf(query) > -1 || item.email.toLowerCase().indexOf(query) > -1)
    });
    this.setState({query, filteredItems});
  };

  handleSearch = (e) => {
    e.preventDefault();
    console.log(e.key);
    if (e.key === 'Enter')
      return History.push('?search=' + this.state.query);
  };

  columns = [
    {
      name: 'Text',
      selector: 'text',
      sortable: true,
      //cell: row => <div>{row.genres.map((genre, i) => <div key={i}>{genre}</div>)}</div>,
      //cell: row => <Link to={'/lexicons/' + row.id}>{row.name}</Link>,
    },
    {
      name: 'Sentiment',
      selector: 'sentiment',
      sortable: true,
      cell: row => <InlineEdit item={row} name="sentiment" value={row.sentiment}/>
    },
    {
      name: 'Rate',
      selector: 'rate',
      sortable: true,
      cell: row => <InlineEditRange item={row} name="rate" value={row.rate}/>
    },
    {
      name: '-',
      selector: 'name',
      right: true,
    },
  ];

  render() {
    // const {isAdmin, isEditor} = this.props.AuthStore.me;
    const {tweetsArray} = this.props.SetStore.list.get(this.state.id) || {};
    const tweets = tweetsArray;
    console.log({tweets});
    return (<React.Fragment>
        <div className="list">
          <div className="searchInput text-right pr-4">
            <Input type="text" className="form-control-sm" placeholder="Filter or Search..." value={this.state.query}
                   onKeyUp={this.handleSearch} onChange={this.handleFilter}/>
            {/*<small className="text-muted">Total result: {this.props.count}</small>*/}
          </div>
          <div className="row">
            {tweets &&
            <DataTable
              noHeader={true}
              columns={this.columns}
              data={tweets}
            />}
            {/*{this.renderItems()}*/}
          </div>
          {/*{this.props.count &&*/}
          {/*<Pagination page={this.state.page} count={this.props.count}*/}
          {/*            limit={this.state.limit} range={this.state.range}*/}
          {/*            query={{search: this.props.search}}/>}*/}
        </div>
      </React.Fragment>
    );
  }
}

//
// const mapStateToProps = (state) => {
//   return {system: state.system, me: state.auth.me}
// };

export default List//connect(mapStateToProps, {fetchList})(List);
