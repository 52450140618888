import {inject, observer} from 'mobx-react';
// import {autorun} from 'mobx';
import React, {Component} from 'react';
import {Input} from "reactstrap";
import {Affix, Date, Loader, Sentimentor, SetInfo} from "../../components";


@inject('AuthStore', 'TweetStore', 'SetStore')
@observer
class List extends Component {

    constructor(props) {
        super(props);
        const params = new URLSearchParams(props.location.search);
        const search = params.get('search') || '';
        this.state = {
            query: search,
        };
        //
        // autorun(() => {
        // })
    }

    handleSearch = (e) => {
        e.preventDefault();
        if (e.key === 'Enter')
            return this.props.history.push('?search=' + encodeURI(this.state.query));
    };

    handleFilter = (e) => {
        e.preventDefault();
        const query = e.currentTarget.value.toLowerCase();
        this.setState({query});
    };

    handleSet = (e) => {
        e.preventDefault();
        this.props.SetStore.current = e.currentTarget.value;
    };

    handleClose = (e) => {
        e.preventDefault();
        this.props.SetStore.current = null;
    };

    checkInSet = (item) => {
        const {tweets} = this.props.SetStore.list.get(this.props.SetStore.current) || {};
        //const {status, list} = this.props.TweetStore;

        if (typeof item === 'object' && tweets && tweets.hasOwnProperty(item.id)) {
            //console.log(item.id, tweets[item.id]);
            return tweets[item.id]['sentiment'];
        } else return null;
    };

    render() {
        const params = new URLSearchParams(this.props.location.search);
        const search = params.get('search') || '';
        if (search) this.props.TweetStore.search(search);

        const setId = this.props.SetStore.current;
        const set = this.props.SetStore.list.get(setId);

        // const {isAdmin, isEditor} = this.props.AuthStore.me;
        const {list, searchList} = this.props.TweetStore;
        const setStatus = this.props.SetStore.status;
        const sets = [...this.props.SetStore.list.values()];
        const data = [...(searchList || list).values()];
        return (<React.Fragment>
                {!setId
                    ? <div className="list">
                        <label>Please, select a "set" to fill it.</label>
                        <select className="form-control" onChange={this.handleSet}>
                            {setStatus === 'initial'
                                ? <option>Loading ...</option>
                                : <option value={null}>Select a set</option>}
                            {sets.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
                        </select>
                    </div>
                    : <div className="list">
                        <div className="searchInput text-right pr-4">
                            <Input type="text" className="form-control-sm" placeholder="Search in Twitter..."
                                   value={this.state.query}
                                   onKeyUp={this.handleSearch} onChange={this.handleFilter}/>
                            {/*<small className="text-muted">Total result: {this.props.count}</small>*/}
                        </div>
                        <div className="">
                            <Affix top={55} offset={120}>
                                <SetInfo set={set} onClose={this.handleClose}/>
                            </Affix>

                            {search && !data.length && <Loader/>}
                            {!search && !data.length &&
                            <p className="m-3 p-3 text-center text-muted">Please, search a keyword or @username for get
                                some twits.
                                <br/>
                                <Input type="text" className="form-control w-50 mx-auto" placeholder="Search in Twitter..."
                                       value={this.state.query}
                                       onKeyUp={this.handleSearch} onChange={this.handleFilter}/>
                            </p>}

                            {data.length ? <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Tweet</th>
                                    <th scope="col">-</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.map((item, i) => {
                                    if (item.isRemoved) return null;
                                    const data = <div>
                                        <p>{item.text}</p>
                                        <small className="d-block">
                                            <a href={'https://twitter.com/' + item.username} target="_blank"
                                               rel="noopener noreferrer">{item.name}</a> - <a
                                            href={'https://twitter.com/' + item.username + '/status/' + item.id}
                                            target="_blank"
                                            rel="noopener noreferrer"><Date date={item.createdAt}/></a>
                                            <div>
                                                followers: {item.followers}{' - '}
                                                friends: {item.friends}{' - '}
                                                favorite: {item.favorite}{' - '}
                                                verified: {String(item.verified)}{' - '}
                                            </div>
                                        </small>
                                    </div>;

                                    return (<tr key={'twit' + item.id}>
                                        <th scope="row">{i + 1}</th>
                                        <td>{data}</td>
                                        <td width={200}>
                                            <Sentimentor item={item} name="sentiment" value={this.checkInSet(item)}/>
                                        </td>
                                    </tr>);

                                })}
                                </tbody>
                            </table> : ''}
                        </div>
                    </div>}
            </React.Fragment>
        );
    }
}

export default List
