import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Input} from "reactstrap";

import DataTable from 'react-data-table-component';
import {Loader} from "../../components";


@inject('AuthStore', 'LexiconStore')
@observer
class List extends Component {

    constructor(props) {
        super(props);
        const params = new URLSearchParams(props.location.search);
        const search = params.get('search') || '';
        const page = params.get('page') || 1;
        this.state = {
            me: props.me,
            count: 0,
            page,
            limit: 10,
            range: 10,
            items: [],
            query: search,
            search: search,
            filteredItems: false,
            system: false
        };

        // autorun(() => {
        //   props.LexiconStore.isReady()
        // })
    }

    handleFilter = (e) => {
        e.preventDefault();
        console.log(e.currentTarget.value);
        const query = e.currentTarget.value.toLowerCase();
        const filteredItems = this.state.items.filter(item => {
            console.log({item});
            return (item.name.toLowerCase().indexOf(query) > -1 || item.surname.toLowerCase().indexOf(query) > -1
                || item.username.toLowerCase().indexOf(query) > -1 || item.email.toLowerCase().indexOf(query) > -1)
        });
        this.setState({query, filteredItems});
    };

    handleSearch = (e) => {
        e.preventDefault();
        console.log(e.key);
        if (e.key === 'Enter')
            return History.push('?search=' + this.state.query);
    };

    columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            //cell: row => <div>{row.genres.map((genre, i) => <div key={i}>{genre}</div>)}</div>,
            cell: row => row.isPublished ? row.name :
                <Link to={'/lexicons/' + row.id}>{row.name}</Link>,
        },
        {
            name: 'Words',
            selector: 'id',
            sortable: true,
            cell: row => Object.keys(row.words).length,
        },
        {
            name: 'Published?',
            selector: 'isPublished',
            sortable: true,
            cell: row => row.isPublished ? 'True' : 'False',
        },
        {
            name: 'Edit',
            selector: 'name',
            right: true,
            cell: row => row.isPublished ? <i>(locked)</i> :
                <Link to={'/lexicons/' + row.id}>edit</Link>,
        },
    ];

    render() {
        // const {isAdmin, isEditor} = this.props.AuthStore.me;
        const {status, list} = this.props.LexiconStore;
        const data = [...list.values()].filter(i => i.name.toLowerCase().indexOf(this.state.query) > -1);
        return (<React.Fragment>
                <div className="list">
                    <div className="searchInput text-right pr-4">
                        <Input type="text" className="form-control-sm" placeholder="Filter or Search..."
                               value={this.state.query}
                               onKeyUp={this.handleSearch} onChange={this.handleFilter}/>
                    </div>
                    <div className="row">
                        {status === 'initial'
                            ? <Loader/>
                            : <DataTable
                                noHeader={true}
                                columns={this.columns}
                                data={data}
                                pagination={true}
                                defaultSortField={'name'}
                            />}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

//
// const mapStateToProps = (state) => {
//   return {system: state.system, me: state.auth.me}
// };

export default List//connect(mapStateToProps, {fetchList})(List);
