import {inject, observer} from "mobx-react";
import React, {useEffect, useState} from 'react';


export const TagInput = inject('UIStore')(observer((props) => {

    const error = props.error || null;
    const [items, setItems] = useState((props.items || []));
    const [value, setValue] = useState(props.value || '');
    const [className, setClassName] = useState(props.className || '');

    useEffect(() => {
        setItems((props.items || []))
    }, [props]);

    const handleFocus = (e) => {
        setClassName(className + ' focused')
    };
    const handleBlur = (e) => {
        if (!value.length)
            setClassName(className.replace('/ focused/g', ''))
    };
    const handleChange = (e) => {
        const el = e.currentTarget;
        const value = el.value;
        if (value) {
            setClassName(className + ' focused')
        }
        setValue(value);
        if (props.hasOwnProperty('onChange'))
            props.onChange(items)
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (value.length) {
                if (props.hasOwnProperty('onChange'))
                    props.onChange([...items, value])
                setItems([...items, value]);
                setValue('');
            }
        }
        if (items.length && e.key === 'Backspace' && !value.length) {
            setItems(items.slice(0, items.length - 1));
        }
    };

    const handleRemoveItem = (index) => {
        return () => {
            setItems(items.filter((item, i) => i !== index));
        }
    };

    return (
        <React.Fragment>
                <div className='vertical tag-input'>
                    <input placeholder={props.placeholder} className="form-control" type="text" value={value}
                           onFocus={handleFocus}
                           onBlur={handleBlur}
                           onChange={handleChange}
                           onKeyDown={handleKeyDown}/>
                    <ul>
                        {items.map((item, i) => item &&
                            <li key={i} onClick={handleRemoveItem(i)}>
                                {item}
                                <i className="fa fa-close"/>
                            </li>
                        )}
                    </ul>
                </div>
            {error &&  <small className="form-text text-danger">{error}</small>}
        </React.Fragment>
    );
}));
