import React, {Component} from 'react';
import nlp from '../../assets/img/nlp.png';
import {HashLink} from 'react-router-hash-link';
import kosgeb from '../../assets/img/kosgeb.jpg';
import cosmos from '../../assets/img/cosmos.png';

class Welcome extends Component {
  render() {
    return <React.Fragment>
      <header className="intro">
        <div className="container">
          <div className="intro-text">
            <h2 className="intro-lead-in">Welcome,</h2>
            <h1 className="intro-heading text-uppercase">BIG DATA <br/>IS WATCHING YOU <br/><span
              className="highlight">SENTIMENTTR</span></h1>
            <HashLink smooth className="btn btn-outline-secondary btn-xl text-uppercase text-white" to="/#about">Tell Me
              More</HashLink>
          </div>
        </div>
      </header>
      <section id="about" className="container">
        <div className="row justify-content-center">
          <div className="col-md-5">
            <img className="container-fluid" src={nlp} alt="nlp"/>
          </div>
          <div className="col-md-7">
            <h2 className="section-heading">SENTIMENTTR</h2>
            <h3 className="section-subheading text-muted mb-4 font-italic h5">Turkish Sentiment Analyse Service</h3>
            <p>
              Hi, we are sentimenttr,</p>
            <p>
              We are a data science team that is specialized on NLP (Natural Language Processing), AI (Artificial
              Intelligence), ML (Machine Learning) and DL (Deep Learning).
            </p>
            <p>
              We working on many areas to analyze sentiment, human behaviour, special interests and even person
              detection from Big Data
            </p>

            <div className="d-flex flex-row ">
            <a href="http://cosmosegitim.com/" target="_blank" rel="noopener noreferrer">
              <img src={cosmos} alt="COSMOS" width={160}/><br/>
            </a>
            <a href="https://www.kosgeb.gov.tr/" target="_blank" rel="noopener noreferrer">
              <img src={kosgeb} alt="KOSGEB" width={140}/><br/>
              www.kosgeb.gov.tr
            </a>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>;
  }
}

export default Welcome;
