import React, {Component} from 'react';

class Projects extends Component {
  render() {
    return <section id="features">
      <div className="container">
        <h1 className="h3">Projects ></h1>
        <h2 className="pb-3">Kosgeb Arge ve İnovasyon Projesi - Twitter Anlamlandırma Motoru</h2>
        <p>
          Kosgeb desteği kapsamında gerçekleştirdiğimiz projemiz; yazılı Türkçe metinlerin duygu durum analizini
          gerçekleştirerek, metinlerin olumlu,olumsuz ve nötr olarak içeriklerini sınıflandırmaktadır.
        </p>
        <p>
          Sentimenttr; Türkçe duygu durum analizi servisidir. İnternet üzerinden online hizmet (web servisi) olarak
          çalışır. Fonksiyonları ise çoklu metin işleme, farklı abonelik tipleri, ayrıntılı raporlamadır.
        </p>
        <p>
          İnternette her gün ürün/hizmet yorumu ve şikayet, öneri gibi metinler paylaşılmaktadır. Bu metinlerin,
          insanlar tarafından okunup değerlendirilmesi büyük bir iş yükü gerektirir. Metinlerin bilgisayar tarafından
          içeriklerinin anlaşılarak değerlendirilmesi ve ilgili birimlere ulaştırılması, gruplanması, etiketlenmesi gibi
          işlemler iş yükünü azaltmakta ve hizmet kalitesi, hızını arttırmaktadır. Online hizmet veren ve sosyal medya
          hesabı olan bütün işletme ve kurumlar bu problemden etkilenmektedir.
        </p><p>
        Projemiz, Türkçe metinlerin olumlu, olumsuz, nötr şeklindeki içeriklerini sınıflandırarak bu probleme bir çözüm
        sunmaktadır. Metinlerin otomatik olarak sınıflandırılması, ilgili birimlere iletilmesi, otomatik olarak
        cevaplanması ile insan iş yükü azalır, hizmet kalitesi ve hızı artar, müşteri memnuniyeti artar. Ayrıca
        ayrıntılı olarak raporlama ile çeşitli veri analizlerinin yapılabilmesi de mümkündür.
      </p>

      </div>
    </section>;
  }
}

export default Projects;
