import firebase from 'firebase/app';
import {stores} from "stores";

const toDate = (date) => {
    return date instanceof firebase.firestore.Timestamp ? String(date.toDate()) : (date || String(new Date()))
};

export class ModelModel {

    constructor(data) {
        this.id = data.id || null;
        this.name = data.name;
        this.description = data.description || null;
        this.model = data.model || null;
        this.vectorizer = data.vectorizer || null;
        this.score = data.score || 0;
        this.sets = data.sets || []
        this.createdBy = data.createdBy;
        this.createdAt = toDate(data.createdAt);
        this.updatedAt = toDate(data.updatedAt);
        console.log(this);
    }


    save = async () => {
        try {
            const {id, name, description, tweets, createdBy, createdAt, updatedAt} = this;
            if (id) {
                await firebase.firestore()
                    .collection('sets')
                    .doc(String(this.id))
                    .update({id, name, description, tweets, createdBy, createdAt, updatedAt})
                    .then()
                    .catch(error => stores.SystemMessageStore.handleError(error));
            } else {
                await firebase.firestore()
                    .collection('sets')
                    .add({id, name, description, tweets, createdBy, createdAt, updatedAt})
                    .then()
                    .catch(error => stores.SystemMessageStore.handleError(error));
            }
        } catch (error) {
            stores.SystemMessageStore.handleError(error)
        }
    };
}
