import UIStore from './UIStore';
import SystemMessageStore from './SystemMessageStore';
// import NotificationStore from './NotificationStore';
import AuthStore from './AuthStore';
import UserStore from './UserStore';

import LexiconStore from './LexiconStore';
import TweetStore from './TweetStore';
import SetStore from './SetStore';
import ModelStore from './ModelStore';

class RootStore {
  constructor() {
    this.UIStore = new UIStore(this);
    this.SystemMessageStore = new SystemMessageStore(this);
    // this.NotificationStore = new NotificationStore(this);
    this.AuthStore = new AuthStore(this);
    this.UserStore = new UserStore(this);

    this.LexiconStore = new LexiconStore(this);
    this.TweetStore = new TweetStore(this);
    this.SetStore = new SetStore(this);
    this.ModelStore = new ModelStore(this);
  }
}

export const stores = new RootStore();
