// import cookie from "react-cookies";

import firebase from "firebase/app";

export class _base {
    API_URL = process.env.REACT_APP_API_URL;
    FIREBASE_URL = process.env.REACT_APP_LOGIN_URL;

    getToken = async () => {
        try {
            return await firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(idToken => {
                    console.log({idToken});
                    return idToken;
                })
                .catch(e => {
                    return false;
                });
        } catch (e) {
            console.log({e})
        }
    };

    get = async (endpoint, params = {}, secured = true) => {
        const token = secured ? await this.getToken().then() : null;
        params = params ? Object.keys(params)
            .map(k => encodeURI(k) + '=' + encodeURI(params[k]))
            .join('&') : '';
        const url = this.API_URL + endpoint + (params ? '?' + params : '');
        const response = await fetch(url, {
            method: "GET",
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            mode: 'cors',
        });
        const result = await response.json();
        if (response.status === 200)
            return result || true;

        throw new Error(result['error_message']);
    };

    post = async (endpoint, data, secured = true) => {
        const token = secured ? await this.getToken() : null;
        const url = this.API_URL + endpoint;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
            body: JSON.stringify(data)
        });
        const result = await response.json();
        if (response.status === 200)
            return result || true;

        throw new Error(result['error_message']);

    };


    put = async (endpoint, data, secured = true) => {
        const token = secured ? await this.getToken() : null;
        const url = this.API_URL + endpoint;
        const response = await fetch(url, {
            method: "PUT",
            headers: {'Content-Type': 'application/json', 'Authorization': token},
            mode: 'cors',
            body: JSON.stringify(data)
        });
        const result = await response.json();
        if (response.status === 200)
            return result || true;

        throw new Error(result['error_message']);
    };

    delete = async (endpoint, id, secured = true) => {
        const token = secured ? await this.getToken() : null;
        const url = this.API_URL + endpoint;
        const response = await fetch(url, {
            method: "DELETE",
            headers: {'Content-Type': 'application/json', 'Authorization': token},
            mode: 'cors'
        });
        const result = await response.json();
        //console.log({result});
        if (response.status === 200)
            return result || true;

        throw new Error(result['error_message']);
    }
}
