import {inject, observer} from "mobx-react";
import React, {Component} from 'react';
import {Field, Form, Formik} from 'formik'
import * as Yup from 'yup'

const FormikForm = ({
                        sets,
                        values,
                        touched,
                        errors,
                        status,
                        isSubmitting
                    }) => (
    <Form>
        {console.log('formik sets', {values})}
        <fieldset className="form-group">
            <Field className="form-control" type="text" name="name" placeholder="name"/>
            {touched.name && errors.name && <small className="form-text text-danger">{errors.name}</small>}
        </fieldset>
        <fieldset className="form-group">
            <Field className="form-control" component="textarea" name="description"
                   placeholder="Description"/>
            {touched.description && errors.description &&
            <small className="form-text text-danger">{errors.description}</small>}
        </fieldset>
        <fieldset className="form-group">
            <Field className="form-control" component="select" name="sets"
                   placeholder="Select a set">
                {(values.options || []).map(i => <option value={i.value}>{i.label}</option>)}
            </Field>
            {touched.sets && errors.sets &&
            <small className="form-text text-danger">{errors.sets}</small>}
        </fieldset>
        {status && status.error && <div className="alert alert-danger">
            <small>{String(status.error)}</small>
        </div>}
        {/*{status && status.success && <div className="alert alert-success">*/}
        {/*  <small>{status.success}</small>*/}
        {/*</div>}*/}
        <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
            {isSubmitting && <span><i className="fa fa-circle-notch fa-spin"/>&nbsp;</span>}
            Train a New Model
        </button>
    </Form>);

@inject('AuthStore', 'SetStore', 'ModelStore')
@observer
class EnhancedForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            success: null,
            error: null
        };
    }

    handleSubmit = async (values, {props, setFieldError, setSubmitting, setStatus}) => {
        setStatus(null);
        setSubmitting(true);
        const data = {
            name: values.name,
            description: values.description,
            sets: [values.sets],
        };
        try {
            await this.props.ModelStore.train(data);
            setStatus({'success': 'The new set created successfully!'});
            setSubmitting(false);
        } catch (errors) {
            setStatus({'error': errors});
            setSubmitting(false);
        }
    };

    render() {

        const sets = [...this.props.SetStore.list.values()].map(i => {
            return {value: i.id, label: i.name}
        });

        console.log({sets});
        return (
            <div className="row justify-content-md-center">
                <div className="col-md-6">
                    <Formik component={FormikForm}
                            enableReinitialize="true"
                            initialValues={{
                                name: '',
                                description: '',
                                sets: '',
                                options: sets
                            }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().required('Name is required'),
                                description: Yup.string().max(200, 'Description must be under 200 characters or shorter')
                            })}
                            onSubmit={this.handleSubmit}/>
                </div>
            </div>)
    }
}

export default EnhancedForm
