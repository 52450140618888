import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Input} from "reactstrap";
import {Date} from "components";
// import sentiment from "sentiment-turkish";
// import {LexiconTester} from "../../helpers/LexiconTester";
//
// import DataTable from 'react-data-table-component';
// import {Loader} from "../../components";


@inject('AuthStore', 'SetStore', 'LexiconStore', 'ModelStore')
@observer
class List extends Component {

    constructor(props) {
        super(props);
        const params = new URLSearchParams(props.location.search);
        const search = params.get('search') || '';
        const page = params.get('page') || 1;
        this.state = {
            me: props.me,
            count: 0,
            page,
            limit: 10,
            range: 10,
            items: [],
            query: search,
            search: search,
            filteredItems: false,
            system: false
        };

        // autorun(() => {
        //   props.SetStore.isReady()
        // })
    }

    handleChange = (e) => {
        const {name, value} = e.currentTarget;
        this.setState({[name]: value})
        // const tester = new LexiconTester();
        // tester.lexicon = {"dışlanmış": -2};
        // //console.log(sentiment('dışlanmış'));
        // console.log(tester.test('dışlanmış'));
    };

    handleFilter = (e) => {
        e.preventDefault();
        console.log(e.currentTarget.value);
        const query = e.currentTarget.value.toLowerCase();
        const filteredItems = this.state.items.filter(item => {
            console.log({item});
            return (item.name.toLowerCase().indexOf(query) > -1 || item.surname.toLowerCase().indexOf(query) > -1
                || item.username.toLowerCase().indexOf(query) > -1 || item.email.toLowerCase().indexOf(query) > -1)
        });
        this.setState({query, filteredItems});
    };

    handleSearch = (e) => {
        e.preventDefault();
        console.log(e.key);
        if (e.key === 'Enter')
            return History.push('?search=' + this.state.query);
    };


    handleSubmit = (e) => {
        e.preventDefault();
        const {set, lexicon, model} = this.state;
        return this.props.history.push('/tests/do?set=' + set + '&lexicon=' + lexicon + '&model=' + model);
    };

    renderItems = () => {
        // const {isAdmin, isEditor} = this.props.AuthStore.me;
        const {list} = this.props.SetStore;
        console.log('data', list, typeof list);
        //if (!(status === 'ready')) return false;
        if (!list) return false;
        //const items = this.props.filteredItems || this.props.system.data.users;
        return [...list.values()].map(item => {
            return <div className="col-sm-4" key={item.id}>
                <div className="card mb-2">
                    <div className="card-body p-2">
                        <p>
                            <h3>{item.name}</h3>
                            <small>{item.id}</small>
                        </p>
                    </div>
                </div>
            </div>;
        })
    };

    columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            //cell: row => <div>{row.genres.map((genre, i) => <div key={i}>{genre}</div>)}</div>,
            cell: row => <Link to={'/tests/' + row.id + '/test'}>{row.name}</Link>,
        },
        {
            name: 'UpdatedAt',
            selector: 'updatedAt',
            sortable: true,
            cell: row => <Date date={row.updatedAt}/>
        },
        {
            name: <i className="positive">=)</i>,
            selector: 'positives',
            sortable: true,
            cell: row => <span className="positive">{row.positives}</span>,
        },
        {
            name: <i className="negative">=(</i>,
            selector: 'negatives',
            sortable: true,
            cell: row => <span className="negative">{row.negatives}</span>,
        },
        {
            name: <i className="neutral">=|</i>,
            selector: 'neutrals',
            sortable: true,
            cell: row => <span className="neutral">{row.neutrals}</span>,
        },
        {
            name: 'Total',
            selector: 'total',
            sortable: true,
            cell: row => <span>{row.total}</span>,
        },
        // {
        //   name: 'Edit',
        //   selector: 'name',
        //   right: true,
        // },
    ];

    render() {
        // const {isAdmin, isEditor} = this.props.AuthStore.me;
        const sets = [...this.props.SetStore.list.values()]
        const lexicons = [...this.props.LexiconStore.list.values()]
        const models = [...this.props.ModelStore.list.values()]

        // const lexicon = {};
        // const {name, wordsArray} = this.props.LexiconStore.list.get('zHkkQzZzXBvWvA2YJkWe') || {};
        // if (wordsArray && wordsArray.length) {
        //     wordsArray.forEach(i => {
        //         lexicon[i.turkish] = i.rate;
        //     })
        // }

        //
        // const tester = new LexiconTester();
        // tester.lexicon = lexicon;
        // console.log(tester.test('terketmek seni cok zor değil sevdiğim'));

        return (<React.Fragment>
                <div className="list">
                    <div className="searchInput text-right pr-4">
                        <Input type="text" className="form-control-sm" placeholder="Filter or Search..."
                               value={this.state.query}
                               onKeyUp={this.handleSearch} onChange={this.handleFilter}/>
                    </div>
                    <form onSubmit={this.handleSubmit} className="w-50 mx-auto">
                        <div>
                            <label>Select a set:</label>
                            <select name="set" className="form-control" onChange={this.handleChange}>
                                {!sets.length
                                    ? <option>Loading ...</option>
                                    : <option value={null}>Select a set</option>}
                                {sets.map(i => <option key={i.id} value={i.id}>{i.name}</option>)}
                            </select>
                        </div>
                        <div>
                            <label>Select a lexicon:</label>
                            <select name="lexicon" className="form-control" onChange={this.handleChange}>
                                {!lexicons.length
                                    ? <option>Loading ...</option>
                                    : <option value={null}>Select a lexicon</option>}
                                {lexicons.map(i => <option key={i.id} value={i.id}>{i.name}</option>)}
                            </select>
                        </div>
                        <div>
                            <label>Select a model:</label>
                            <select name="model" className="form-control" onChange={this.handleChange}>
                                {!models.length
                                    ? <option>Loading ...</option>
                                    : <option value={null}>Select a model</option>}
                                {models.map(i => <option key={i.id} value={i.id}>{i.name}</option>)}
                            </select>
                        </div>
                        <div className="py-3">
                            <button type="submit" className="btn btn-primary w-100">Test It</button>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

export default List