import firebase from 'firebase/app';
import {computed, observable, toJS} from "mobx";
import {stores} from "stores";

const toDate = (date) => {
  return date instanceof firebase.firestore.Timestamp ? String(date.toDate()) : (date || String(new Date()))
};

export class SetModel {

  @observable _tweets = observable.map({}, {deep: true});

  constructor(data) {
    this.id = data.id || null;
    this.name = data.name;
    this.description = data.description;
    this._tweets = data.tweets || {};
    this.createdBy = data.createdBy || stores.AuthStore.me.uid;
    this.createdAt = toDate(data.createdAt);
    this.updatedAt = toDate(data.updatedAt);
    console.log(this);
  }

  @computed
  get tweets() {
    return toJS(this._tweets)
  }

  @computed
  get total() {
    return Object.values(this._tweets).length
  }

  @computed
  get positives() {
    return Object.values(this._tweets).filter(i => i.sentiment === 1).length
  }

  @computed
  get negatives() {
    return Object.values(this._tweets).filter(i => i.sentiment === -1).length
  }

  @computed
  get neutrals() {
    return Object.values(this._tweets).filter(i => i.sentiment === 0).length
  }

  @computed
  get tweetsArray() {
    const items = toJS(this._tweets);
    //console.log(toJS(this._tweets));
    const list = [];
    for (const id in items) {
      if (!items.hasOwnProperty(id)) continue;
      const item = items[id];
      item.save = async () => {
        delete item.save;
        const data = {};
        data['tweets.' + String(id)] = item;
        console.log('on save', data);
        await firebase.firestore()
          .collection('sets')
          .doc(this.id)
          .update(data);
        return true
      };
      item.remove = async () => {
        delete item.remove;
        delete item.save;
        const data = {};
        data['tweets.' + String(id)] = firebase.firestore.FieldValue.delete();
        console.log('on delete', data);
        await firebase.firestore()
          .collection('sets')
          .doc(this.id)
          .update(data);
        stores.SetStore.pushUndo(item);
        return true
      };
      item.undo = async () => {
        delete item.undo;
        const data = {};
        data['tweets.' + String(id)] = item;
        console.log('on undo', data);
        await firebase.firestore()
          .collection('sets')
          .doc(this.id)
          .update(data);
        return true
      };
      list.push(item)
    }
    return list;//Object.values(toJS(this._words))
  }

  //
  // @computed
  // get born() {
  //   return this._born instanceof firebase.firestore.Timestamp ? this._born.toDate() : this._born
  // }
  //
  // set born(date) {
  //   this._born = date;
  // }

  save = async () => {
    try {
      const {id, name, description, tweets, createdBy, createdAt, updatedAt} = this;
      if (id) {
        await firebase.firestore()
          .collection('sets')
          .doc(String(this.id))
          .update({id, name, description, tweets, createdBy, createdAt, updatedAt})
          .then()
          .catch(error => stores.SystemMessageStore.handleError(error));
      } else {
        await firebase.firestore()
          .collection('sets')
          .add({id, name, description, tweets, createdBy, createdAt, updatedAt})
          .then()
          .catch(error => stores.SystemMessageStore.handleError(error));
      }
    } catch (error) {
      stores.SystemMessageStore.handleError(error)
    }
  };

  // toggleAdmin = async () => {
  //   try {
  //     await stores.UserStore.service.toggleAdmin(this.uid, {admin: !this.isAdmin()})
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  // @computed
  // get createdAt() {
  //   return this._createdAt instanceof firebase.firestore.Timestamp ? this._createdAt.toDate() : this._createdAt
  // }
  //
  // set createdAt(date) {
  //   this._createdAt = date;
  // }
  //
  // @computed
  // get updatedAt() {
  //   return this._updatedAt instanceof firebase.firestore.Timestamp ? this._updatedAt.toDate() : this._updatedAt
  // }
  //
  // set updatedAt(date) {
  //   this._updatedAt = date;
  // }
}
