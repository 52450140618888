import {action, computed, observable, runInAction} from 'mobx';
import firebase from 'firebase/app';
import SetService from '../services/SetService';
import {SetModel} from '../models/SetModel';

export default class SetStore {
  @observable isReady = false;
  dbListener = null;
  @observable _list = new observable.map();
  //@observable list = [];
  @observable current = null;

  @observable data = null;
  @observable status = "initial";
  @observable searchQuery = "";

  @observable _undos = [];
  @observable ctrZEvent = null;

  @observable _scores = [0, 0, 0];

  constructor(Stores) {
    this.stores = Stores;
    this.service = new SetService();
    this.model = SetModel;
  }

  get list() {
    if (!this.dbListener) {
      this.listenToDB();
    }
    return this._list;
  }

  @computed
  set list(item) {
    return this._list.set(item);
  }

  create = async (data) => {
    const item = new this.model(data);
    return await item.save();
  };

  countScore = async (score) => {
    console.log()
    if (score)
      this._scores[0]++;
    else
      this._scores[1]++;

    this._scores[2] = this._scores[0] / (this._scores[0] + this._scores[1]) * 100

  };

  get score() {
    return this._scores;
  }

  listenToDB = () => {
    this.dbListener = firebase
      .firestore()
      .collection('sets')
      //.orderBy('createdAt', 'asc')
      .onSnapshot(snapshot => {
        runInAction(() => {
          this.status = 'listening';
          this.isReady = true;
          snapshot.docChanges().forEach(change => {
            const doc = change.doc.data(); // TODO add words filter
            doc.id = change.doc.id;
            if (change.type === 'added' || change.type === 'modified') {
              this.handleAdd(doc);
            }
            if (change.type === 'removed') {
              this.handleRemove(doc.id);
            }
          });
        });
      });
  };

  @action
  handleRemove(docId) {
    this._list.delete(docId);
  }

  @action
  handleAdd(doc) {
    const item = new this.model(doc);
    if (this._list.has(doc.id)) {
      if (JSON.stringify(item) !== JSON.stringify(this._list.get(doc.id)))
        this._list.set(doc.id, item);
    } else
      this._list.set(doc.id, item);
  }

//
// toJS() {
//   return this.todos.map(item => item.toJS());
// }
//
// static fromJS(array) {
//   const userStore = new UserStore();
//   userStore.list = array.map(item => this.model.fromJS(userStore, item));
//   return userStore;
// }

// @action setUserLocation = () => {
//   navigator.geolocation.getCurrentPosition(position => {
//     runInAction(() => {
//       this.userLocation.lat = position.coords.latitude;
//       this.userLocation.lng = position.coords.longitude;
//     });
//   });
// };

  pushUndo = (item) => {
    this._undos.push(item);
    console.log('set stores undo', this.ctrZEvent, item);
    if (!this.ctrZEvent)
      this.ctrZEvent = document.addEventListener('keydown', async e => {
        if (e.key === 'z' && (e.metaKey || e.ctrlKey) && !this.undoing) {
          const item = this._undos.pop();
          console.log('UNDO', {item});
          if (item && item.hasOwnProperty('undo')) {
            this.undoing = true;
            await item.undo();
            this.undoing = false;
          }
        }
        console.log({e});
      });
  }
}
