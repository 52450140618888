import React from 'react';
import {Link, Route} from 'react-router-dom';
import List from "./List";
import Edit from "./Edit";
import Show from "./Show";

const routes = [
  {
    path: '/lexicons/add',
    link: null,//'/lexicons',
    icon: 'fa fa-plus',
    exact: true,
    title: () => 'New Lexicon',
    name: 'Create New',
    main: Edit
  },
  {
    path: '/lexicons(/list)?',
    link: '/lexicons',
    icon: 'fa fa-list',
    exact: true,
    title: () => 'Lexicon List',
    name: 'List',
    main: List
  },
  {
    path: '/lexicons/:id/edit',
    link: null,
    icon: 'fa fa-image',
    exact: true,
    title: () => 'Edit Lexicon',
    name: 'Edit',
    main: Edit
  },
  {
    path: '/lexicons/:id',
    link: null,
    icon: 'fa fa-image',
    exact: true,
    title: () => 'Lexicon',
    name: 'Show',
    main: Show
  },
];

class Lexicons extends React.PureComponent {

  render() {
    return <section className="container">
      <div className="row">
        <nav className="col-md-2 d-none d-md-block border-right sidebar">
          <div className="sidebar-sticky">
            <h5 className="sidebar-heading text-muted"><i className="fa fa-file"/> Lexicons</h5>
            <ul className="nav flex-column small">
              {routes.map((route, index) => (
                route.link && <li key={index} className="nav-item">
                  <Link className="nav-link active" to={route.link}>
                    <i className={route.icon}/> {route.name}
                  </Link>
                </li>))}
            </ul>
          </div>
        </nav>
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
          <h1 className="main-heading text-primary lined lined-align-left lined-primary">
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.title}
              />
            ))}
          </h1>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.main}
            />
          ))}
        </main>
      </div>
    </section>;
  }
}

export default Lexicons;
