import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Input} from "reactstrap";
import {Date, Loader} from "components";

import DataTable from 'react-data-table-component';


@inject('AuthStore', 'ModelStore')
@observer
class List extends Component {

    constructor(props) {
        super(props);
        const params = new URLSearchParams(props.location.search);
        const search = params.get('search') || '';
        const page = params.get('page') || 1;
        this.state = {
            me: props.me,
            count: 0,
            page,
            limit: 10,
            range: 10,
            items: [],
            query: search,
            search: search,
            filteredItems: false,
            system: false
        };
    }

    handleFilter = (e) => {
        e.preventDefault();
        console.log(e.currentTarget.value);
        const query = e.currentTarget.value.toLowerCase();
        const filteredItems = this.state.items.filter(item => {
            console.log({item});
            return (item.name.toLowerCase().indexOf(query) > -1 || item.surname.toLowerCase().indexOf(query) > -1
                || item.username.toLowerCase().indexOf(query) > -1 || item.email.toLowerCase().indexOf(query) > -1)
        });
        this.setState({query, filteredItems});
    };

    handleSearch = (e) => {
        e.preventDefault();
        console.log(e.key);
        if (e.key === 'Enter')
            return History.push('?search=' + this.state.query);
    };

    renderItems = () => {
        // const {isAdmin, isEditor} = this.props.AuthStore.me;
        const {list} = this.props.SetStore;
        console.log('data', list, typeof list);
        //if (!(status === 'ready')) return false;
        if (!list) return false;
        //const items = this.props.filteredItems || this.props.system.data.users;
        return [...list.values()].map(item => {
            return <div className="col-sm-4" key={item.id}>
                <div className="card mb-2">
                    <div className="card-body p-2">
                        <p>
                            <h3>{item.name}</h3>
                            <small>{item.id}</small>
                        </p>
                    </div>
                </div>
            </div>;
        })
    };

    columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            //cell: row => <div>{row.genres.map((genre, i) => <div key={i}>{genre}</div>)}</div>,
            cell: row => <Link to={'/models/' + row.id + '/add'}>{row.name}</Link>,
        },
        {
            name: 'CreatedBy',
            selector: 'createdBy',
            sortable: true
        },
        {
            name: 'CreatedAt',
            selector: 'createdAt',
            sortable: true,
            cell: row => <Date date={row.createdAt}/>
        },
        {
            name: 'Sets',
            selector: 'sets',
            sortable: true,
            cell: row => row.sets.map(i => <span>{i}</span>)
        },
        // {
        //   name: 'Edit',
        //   selector: 'name',
        //   right: true,
        // },
    ];

    render() {
        // const {isAdmin, isEditor} = this.props.AuthStore.me;
        const {status, list} = this.props.ModelStore;
        const data = [...list.values()].filter(i => i.name.toLowerCase().indexOf(this.state.query) > -1);

        console.log({list});
        return (<React.Fragment>
                <div className="list">
                    <div className="searchInput text-right pr-4">
                        <Input type="text" className="form-control-sm" placeholder="Filter or Search..."
                               value={this.state.query}
                               onKeyUp={this.handleSearch} onChange={this.handleFilter}/>
                    </div>
                    <div className="row">
                        {status === 'initial'
                            ? <Loader/>
                            : <DataTable
                                noHeader={true}
                                columns={this.columns}
                                data={data}
                                pagination={true}
                                defaultSortField={'name'}
                            />}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default List