import {inject} from 'mobx-react';
import React, {useEffect, useState} from 'react';

export const SentimentResult = inject("SetStore")((props) => {
    //console.log("PROPS", props)
    // const sentiment = props.value;
    // const [value, setValue] = useState(props.value || 0);
    const [loader, setLoader] = useState('');

    const sentiment = props.sentiment;
    const score = props.score > 0 ? 1 : props.score < 0 ? -1 : 0;

    useEffect(() => {
        setLoader('loader')
        props.SetStore.countScore(score === sentiment)
        setLoader('')
    }, [props.SetStore, score, sentiment]);


    const getValueClass = (value) => {
        if (value === null) return;
        const c = ['negative', 'notr', 'positive'];
        return c[value + 1]
    };

    return (
        <div id={'sentimentor' + props.item.id} className={'sentimentor ' + getValueClass(score) + ' ' + loader}
             style={{width: '180px'}}>
            {score > 0
                ? <span className="positive"><span>=)</span></span>
                : score < 0 ? <span className="negative"><span>=(</span></span>
                    : <span className="notr"><span>=|</span></span>}
            <span className="pl-1">{score === sentiment ? 'true' : 'false'}</span>
        </div>
    )
});
