import firebase from 'firebase/app';
import {computed, observable, ObservableMap, toJS} from "mobx";
import {stores} from "stores";

// class WordModel {
//     constructor(data) {
//         this.original = data.original;
//         this.turkish = data.turkish;
//         this.rate = data.rate;
//         this.status = data.status;
//     }
// }

export class LexiconModel {

    //@observable uid;
    @observable cover;
    @observable avatar;
    @observable _born;
    @observable customClaims;
    @observable _words = new ObservableMap();
    @observable isPublished;


    constructor(data) {
        console.log('user model constructor', data);
        this.id = data.id;
        this.name = data.name;
        this._words = data.words || {};
        this.notes = data.notes;
        this.createdBy = data.createdBy;
        this.createdAt = data.createdAt || new Date();
        this.updatedAt = data.updatedAt || new Date();
        this.isPublished = data.isPublished || false;
    }

    // get id() {
    //   return this.uid
    // }
    //
    @computed
    get words() {
        return toJS(this._words)
    }


    @computed
    get lexicon() {
        const items = toJS(this._words);
        const list = {};
        for (const id in items) {
            const i = items[id];
            list[i.turkish] = i.rate;
        }
        return list;
    };

    @computed
    get wordsArray() {
        // db.collection('heroes').doc(`xxxXXXxxx`).update({
        //   "fantasticsFours": {
        //     "humanTorch":{ ... }
        //   }
        // })
        const items = toJS(this._words);
        console.log(toJS(this._words));
        const list = [];
        for (const id in items) {
            const item = items[id];
            item.save = async () => {
                delete item.save;
                const data = {};
                data['words.' + id] = item;
                console.log('on save', data)
                await firebase.firestore()
                    .collection('lexicons')
                    .doc(this.id)
                    .update(data);
                return true
            };
            list.push(item)
        }

        ;
        return list;//Object.values(toJS(this._words))
    }

    //
    // @computed
    // get born() {
    //   return this._born instanceof firebase.firestore.Timestamp ? this._born.toDate() : this._born
    // }
    //
    // set born(date) {
    //   this._born = date;
    // }

    save = async () => {
        try {
            const {uid, first, last, born, bio, avatar, cover} = this;
            await firebase.firestore()
                .collection('users')
                .doc(uid)
                .update({first, last, born, bio, avatar, cover})
                .then()
                .catch(error => stores.SystemMessageStore.handleError(error));
            //await this.service.put(uid, {first, last, born, bio});
            if (uid === stores.AuthStore.me.uid)
                await stores.AuthStore.getUserData({uid});
        } catch (error) {
            stores.SystemMessageStore.handleError(error)
        }
    };

    // toggleAdmin = async () => {
    //   try {
    //     await stores.UserStore.service.toggleAdmin(this.uid, {admin: !this.isAdmin()})
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }

}
