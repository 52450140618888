import {observable} from 'mobx';

export class SystemMessageModel {

  @observable isDisplayed = false;

  constructor(data) {
    this.status = data.status;
    this.code = data.code;
    this.message = data.message;
    this.isDisplayed = false;
    this.createdAt = data.createdAt;
    this.timeless = data.timeless || false;
  }

  hide = () => this.isDisplayed = true;

  hideTimeout = (timeout = 10000) => {
    if (!this.timeless)
      setTimeout(() => this.hide(), timeout)
  };
}
