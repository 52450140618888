// const lexicon = require('../build/build.json');
const tokenizer = require('./tokenizer');

/**
 * These words "flip" the sentiment of the following word.
 */
const negators = {
    'değil': 1,
    'olmaz': 1
};

/**
 * Performs sentiment analysis on the provided input 'phrase'.
 *
 * @param {String} Input phrase
 * @param {Object} Optional sentiment additions to AFINN (hash k/v pairs)
 *
 * @return {Object}
 */
export class LexiconTester {
    lexicon = {};

    test = (phrase, inject, callback) => {

        // Parse arguments
        if (typeof phrase === 'undefined') phrase = '';
        if (typeof inject === 'undefined') inject = null;
        if (typeof inject === 'function') callback = inject;
        if (typeof callback === 'undefined') callback = null;

        // Merge
        // if (inject !== null) {
        //     afinn = Object.assign(afinn, inject);
        // }


        // Storage objects
        const tokens = tokenizer(phrase),
            words = [],
            positive = [],
            negative = [];

        let score = 0;

        // Iterate over tokens
        let len = tokens.length;
        while (len--) {
            const obj = tokens[len];
            if (!this.lexicon || !this.lexicon.hasOwnProperty(obj)) continue;
            let item = Number(this.lexicon[obj]);

            // Check for negation
            // if (len > 0) {
            //     const prevtoken = tokens[len - 1];
            //     if (negators[prevtoken]) item *= -1;
            // }
            if (len !== tokens.length) {
                const nexttoken = tokens[len + 1];
                if (negators[nexttoken]) item *= -1;
            }

            words.push(obj);
            if (item > 0) positive.push(obj);
            if (item < 0) negative.push(obj);

            score += item;
        }

        // Handle optional async interface
        const result = {
            score: score,
            comparative: tokens.length > 0 ? score / tokens.length : 0,
            tokens: tokens,
            words: words,
            positive: positive,
            negative: negative
        };

        if (callback === null) return result;
        process.nextTick(function () {
            callback(null, result);
        });
    };
};
