import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {Input} from "reactstrap";

import DataTable from 'react-data-table-component';
import {InlineEdit, InlineEditRange} from '../../components';


@inject('AuthStore', 'LexiconStore')
@observer
class List extends Component {

    constructor(props) {
        super(props);
        const params = new URLSearchParams(props.location.search);
        const search = params.get('search') || '';
        const page = params.get('page') || 1;
        this.state = {
            me: props.me,
            count: 0,
            page,
            limit: 10,
            range: 10,
            items: [],
            query: search,
            search: search,
            filteredItems: false,
            system: false,
            filter: null,

            id: props.match.params.id
        };

        // autorun(() => {
        //   props.LexiconStore.isReady()
        // })
    }

    handleFilter = (e) => {
        e.preventDefault();
        // console.log(e.currentTarget.value);
        const query = e.currentTarget.value.toLowerCase();
        // const filteredItems = this.state.items.filter(item => {
        //   console.log({item});
        //   return (item.name.toLowerCase().indexOf(query) > -1 || item.surname.toLowerCase().indexOf(query) > -1
        //     || item.username.toLowerCase().indexOf(query) > -1 || item.email.toLowerCase().indexOf(query) > -1)
        // });
        this.setState({query});
    };

    handleSearch = (e) => {
        e.preventDefault();
        if (e.key === 'Enter')
            return History.push('?search=' + this.state.query);
    };

    renderItems = () => {
        // const {isAdmin, isEditor} = this.props.AuthStore.me;
        const {list} = this.props.LexiconStore;
        console.log('data', list, typeof list);
        //if (!(status === 'ready')) return false;
        if (!list) return false;
        //const items = this.props.filteredItems || this.props.system.data.users;
        return [...list.values()].map(item => {
            return <div className="col-sm-4" key={item.id}>
                <div className="card mb-2">
                    <div className="card-body p-2">
                        <p>
                            <h3>{item.name}</h3>
                            <small>{item.id}</small>
                        </p>
                    </div>
                </div>
            </div>;
        })
    };
    columns = [
        {
            name: 'Name',
            selector: 'original',
            sortable: true,
            //cell: row => <div>{row.genres.map((genre, i) => <div key={i}>{genre}</div>)}</div>,
            //cell: row => <Link to={'/lexicons/' + row.id}>{row.name}</Link>,
        },
        {
            name: 'Turkish',
            selector: 'turkish',
            sortable: true,
            cell: row => <InlineEdit tabindex={0} item={row} name="turkish" value={row.turkish}/>
        },
        {
            name: 'Rate',
            selector: 'rate',
            sortable: true,
            cell: row => <InlineEditRange tabindex={0} item={row} name="rate" value={row.rate}/>
        },
        {
            name: '-',
            selector: 'name',
            right: true,
        },
    ];

    render() {
        // const {isAdmin, isEditor} = this.props.AuthStore.me;
        const {wordsArray} = this.props.LexiconStore.list.get(this.state.id) || {};
        const words = (this.state.query) ?
            wordsArray.filter(i => i.original.toLowerCase().indexOf(this.state.query) > -1
                || i.turkish.toLowerCase().indexOf(this.state.query) > -1)
            : wordsArray;
        console.log({words});
        return (<React.Fragment>
                <div className="list">
                    <div className="searchInput text-right pr-4">
                        <Input type="text" className="form-control-sm" placeholder="Filter or Search..."
                               value={this.state.query}
                               onKeyUp={this.handleSearch} onChange={this.handleFilter}/>
                        {/*<small className="text-muted">Total result: {this.props.count}</small>*/}
                    </div>
                    <div className="row">
                        {words &&
                        <DataTable
                            noHeader={true}
                            columns={this.columns}
                            data={words}
                            pagination={true}
                            defaultSortField={'original'}
                        />}
                        {/*{this.renderItems()}*/}
                    </div>
                    {/*{this.props.count &&*/}
                    {/*<Pagination page={this.state.page} count={this.props.count}*/}
                    {/*            limit={this.state.limit} range={this.state.range}*/}
                    {/*            query={{search: this.props.search}}/>}*/}
                </div>
            </React.Fragment>
        );
    }
}

//
// const mapStateToProps = (state) => {
//   return {system: state.system, me: state.auth.me}
// };

export default List//connect(mapStateToProps, {fetchList})(List);
