import {_base} from './_base';
// import {CommonServices} from './CommonServices';
// import {UserServices} from './UserServices';
// import {SummaryServices} from './SummaryServices';
// import {PPCServices} from './PPCServices';
// import {WebsiteServices} from './WebsiteServices';
import {ModelService} from './ModelService';


class Services {
  constructor() {
    const base = new _base();
    // this.Common = new CommonServices(base);
    // this.User = new UserServices(base);
    // this.Summary = new SummaryServices(base);
    // this.PPC = new PPCServices(base);
    // this.Website = new WebsiteServices(base);
    this.Model = new ModelService(base);
  }
}

export const services = new Services();
