import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {Input} from "reactstrap";
import sentiment from "sentiment-turkish";
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis,} from 'recharts';


@inject('AuthStore', 'TweetStore', 'SetStore')
@observer
class Test extends Component {

    constructor(props) {
        super(props);
        const params = new URLSearchParams(props.location.search);
        const search = params.get('search') || '';
        const page = params.get('page') || 1;
        this.state = {
            me: props.me,
            count: 0,
            page,
            limit: 10,
            range: 10,
            items: [],
            query: search,
            search: search,
            filteredItems: false,
            system: false,

            id: props.match.params.id
        };

        // autorun(() => {
        //
        //     const {name, tweets} = this.props.SetStore.list.get(this.state.id) || {};
        // })
    }


    handleFilter = (e) => {
        e.preventDefault();
        console.log(e.currentTarget.value);
        const query = e.currentTarget.value.toLowerCase();
        const filteredItems = this.state.items.filter(item => {
            console.log({item});
            return (item.name.toLowerCase().indexOf(query) > -1 || item.surname.toLowerCase().indexOf(query) > -1
                || item.username.toLowerCase().indexOf(query) > -1 || item.email.toLowerCase().indexOf(query) > -1)
        });
        this.setState({query, filteredItems});
    };

    handleSearch = (e) => {
        e.preventDefault();
        console.log(e.key);
        if (e.key === 'Enter')
            return History.push('?search=' + this.state.query);
    };

    render() {

        const { tweetsArray} = this.props.SetStore.list.get(this.state.id) || {};
        const data = [];
        const scores = {
            positives: 0,
            negatives: 0,
            neutrals: 0,
            apositives: 0,
            anegatives: 0,
            aneutrals: 0,
            pos: 0,
            neg: 0,
            nei: 0
        };

        if (tweetsArray) {
            const total = tweetsArray.length;
            tweetsArray.map(twit => {
                const afinn = sentiment(twit.text);
                const score = afinn.score > 0 ? 1 : afinn.score < 0 ? -1 : 0;

                // if (score > 0)
                //   scores.apositives++;
                // else if (score < 0)
                //   scores.anegatives++;
                // else
                //   scores.aneutrals++;
                //
                // if (twit.sentiment > 0)
                //   scores.positives++;
                // else if (twit.sentiment < 0)
                //   scores.negatives++;
                // else
                //   scores.neutrals++;
                //
                // scores.pos = scores.positives / scores.apositives;
                // scores.neg = scores.anegatives / scores.negatives;
                // scores.neu = scores.neutrals / scores.aneutrals;

                if (score > 0 && twit.sentiment > 0)
                    scores.positives++;
                else if (score < 0 && twit.sentiment < 0)
                    scores.negatives++;
                else if (score === 0 && twit.sentiment === 0)
                    scores.neutrals++;

                scores.pos = scores.positives / total;
                scores.neg = scores.negatives / total;
                scores.neu = scores.neutrals / total;

                console.log({scores});

                data.push({
                    name: twit.text,
                    sentiment: twit.sentiment,
                    AFINN: afinn.score,
                    result: score === twit.sentiment ? 1 : 0
                });
                return twit;
            });
        }

        // const {isAdmin, isEditor} = this.props.AuthStore.me;
        return (<React.Fragment>
                <div className="list">
                    <div className="searchInput text-right pr-4">
                        <Input type="text" className="form-control-sm" placeholder="Filter or Search..."
                               value={this.state.query}
                               onKeyUp={this.handleSearch} onChange={this.handleFilter}/>
                        {/*<small className="text-muted">Total result: {this.props.count}</small>*/}
                    </div>
                    <div className="row mt-5">

                        <LineChart
                            width={940}
                            height={300}
                            data={data}
                            margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis/>
                            <YAxis/>
                            <Tooltip/>
                            <Legend/>
                            <Line type="monotone" dataKey="sentiment" stroke="#8884d8" activeDot={{r: 8}}/>
                            <Line type="monotone" dataKey="AFINN" stroke="#82ca9d"/>
                            <Line type="monotone" dataKey="result" stroke="#D95043"/>
                        </LineChart>

                    </div>
                    <div className="text-center">
                        <span className="mx-2">positive: <span>{(scores.pos * 100).toFixed(2)}</span></span>
                        <span className="mx-2">negative: <span>{(scores.neg * 100).toFixed(2)}</span></span>
                        <span className="mx-2">neutrals: <span>{(scores.neu * 100).toFixed(2)}</span></span>
                        <span
                            className="mx-2">total: <span>{((scores.pos + scores.neg + scores.neu) * 100).toFixed(2)}</span></span>
                    </div>
                    {/*{this.props.count &&*/}
                    {/*<Pagination page={this.state.page} count={this.props.count}*/}
                    {/*            limit={this.state.limit} range={this.state.range}*/}
                    {/*            query={{search: this.props.search}}/>}*/}
                </div>
            </React.Fragment>
        );
    }
}

export default Test
