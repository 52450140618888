import React, {useEffect, useState} from 'react';

export const Sentimentor = (props) => {
    //console.log("PROPS", props)
    // const sentiment = props.value;
    const [value, setValue] = useState(props.value || 0);
    const [loader, setLoader] = useState('');

    useEffect(() => {
        setValue(props.value)
    }, [props.value]);

    const setRemove = async () => {
        await props.item.remove();
    };

    const setPositive = async () => {
        setSentiment(1);
        setLoader('positiveLoader');
    };

    const setNegative = async (e) => {
        setSentiment(-1);
        setLoader('negativeLoader');
    };

    const setNotr = async (e) => {
        setSentiment(0);
        setLoader('notrLoader');
    };

    const setSentiment = async (value) => {
        console.log('props.item', props.item);
        props.item['sentiment'] = value;
        const res = props.item.hasOwnProperty('sentimentor') ? await props.item.sentimentor() : await props.item.save();
        console.log({res});
        setTimeout(() => {
            if (res) {
                setLoader('');
                setValue(value);
            }
        }, 1000)
    };

    const getValueClass = (value) => {
        if (value === null) return;
        const c = ['negative', 'notr', 'positive'];
        return c[value + 1]
    };

    return (
        <div id={'sentimentor' + props.item.id} className={'sentimentor ' + getValueClass(value) + ' ' + loader}
             style={{width: '180px'}}>
            <button onClick={setPositive} className="positive"><span>=)</span></button>
            <button onClick={setNegative} className="negative"><span>=(</span></button>
            <button onClick={setNotr} className="notr"><span>=|</span></button>
            <button onClick={setRemove} className="remove">x</button>
        </div>
    )

};
