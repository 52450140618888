import firebase from 'firebase/app';
import {computed, observable, toJS} from "mobx";
import {stores} from "stores";

// class WordModel {
//   constructor(data) {
//     this.original = data.original;
//     this.turkish = data.turkish;
//     this.rate = data.rate;
//     this.status = data.status;
//   }
// }

export class TweetModel {

  //@observable uid;
  @observable text;
  @observable sentiment;
  @observable isRemoved;

  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.username = data.username;
    this.avatar = data.avatar;
    this.url = data.url || null;
    this.followers = data.followers || 0;
    this.friends = data.friends || 0;
    this.favorite = data.favorite || 0;
    this.retweet = data.retweet || 0;
    this.verified = data.verified;
    this.text = data.text;
    this.createdAt = data.createdAt || new Date();
    this.isRemoved = data.isRemoved || false;
    //this.updatedAt = data.updatedAt || new Date();
  }

  // get id() {
  //   return this.uid
  // }
  //
  @computed
  get words() {
    return toJS(this._words)
  }

  sentimentor = async () => {
    const set = stores.SetStore.current;
    const data = {};
    data['tweets.' + String(this.id)] = {
      id: this.id,
      text: this.text,
      sentiment: this.sentiment,
      updatedAt: new Date()
    };
    console.log('on save', data);
    await firebase.firestore()
      .collection('sets')
      .doc(set)
      .update(data);
    return true
  };

  remove = async () => {
    console.log(this);

    try {
      this.isRemoved = true;
      await firebase.firestore()
        .collection('tweets')
        .doc(String(this.id))
        .update({isRemoved: true})
        .then()
        .catch(error => stores.SystemMessageStore.handleError(error));

      stores.TweetStore.pushUndo(this)

    } catch (error) {
      stores.SystemMessageStore.handleError(error)
    }
  };

  undo = async () => {
    try {
      this.isRemoved = false;
      await firebase.firestore()
        .collection('tweets')
        .doc(String(this.id))
        .update({isRemoved: false})
        .then()
        .catch(error => stores.SystemMessageStore.handleError(error));

    } catch (error) {
      stores.SystemMessageStore.handleError(error)
    }
  };

  save = async () => {
    try {
      const {uid, first, last, born, bio, avatar, cover} = this;
      await firebase.firestore()
        .collection('tweets')
        .doc(uid)
        .update({first, last, born, bio, avatar, cover})
        .then()
        .catch(error => stores.SystemMessageStore.handleError(error));
      //await this.service.put(uid, {first, last, born, bio});
      if (uid === stores.AuthStore.me.uid)
        await stores.AuthStore.getUserData({uid});
    } catch (error) {
      stores.SystemMessageStore.handleError(error)
    }
  };

// toggleAdmin = async () => {
//   try {
//     await stores.UserStore.service.toggleAdmin(this.uid, {admin: !this.isAdmin()})
//   } catch (error) {
//     console.log(error)
//   }
// }

}
