import React, {useMemo} from 'react';
import {Link} from "react-router-dom";

export const SetInfo = (props) => {
  const {id, name, total, positives, negatives, neutrals} = props.set;

  const positive = useMemo(() => <span
    className={'positive' + (positives % 2 ? ' up' : ' down')}>{positives} <i
    className="positive">=)</i></span>, [positives]);
  const negative = useMemo(() => <span
    className={'negative' + (negatives % 2 ? ' up' : ' down')}>{negatives} <i
    className={"negative"}>=)</i></span>, [negatives]);
  const neutral = useMemo(() => <span
    className={'neutral' + (neutrals % 2 ? ' up' : ' down')}>{neutrals} <i
    className="neutral">=)</i></span>, [neutrals]);

  return (
    <div className="set-info">
      <Link to={'/models/' + id + '/add'}>{name}</Link>
      {positive}
      {negative}
      {neutral}
      <span>Total: {total}</span>
      {props.onClose && <span> - <button className='btn btn-sm btn-link' onClick={props.onClose}>turn back to list</button></span>}
    </div>);

};
