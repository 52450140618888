import {autorun} from 'mobx';
import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {Input} from "reactstrap";
// import sentiment from "sentiment-turkish";
import {LexiconTester} from "../../helpers/LexiconTester";
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis,} from 'recharts';


@inject('AuthStore', 'TweetStore', 'SetStore', 'LexiconStore', 'ModelStore')
@observer
class Test extends Component {

    constructor(props) {
        super(props);
        const params = new URLSearchParams(props.location.search);
        const search = params.get('search') || '';
        const page = params.get('page') || 1;
        this.state = {
            me: props.me,
            params,
            count: 0,
            page,
            limit: 10,
            range: 10,
            items: [],
            query: search,
            search,
            filteredItems: false,
            system: false,

            id: props.match.params.id
        };

        autorun(() => {
            const results = this.props.ModelStore.test(params.get('model'), params.get('set')) || {};
            console.log({results})
        })
    }


    handleFilter = (e) => {
        e.preventDefault();
        console.log(e.currentTarget.value);
        const query = e.currentTarget.value.toLowerCase();
        const filteredItems = this.state.items.filter(item => {
            console.log({item});
            return (item.name.toLowerCase().indexOf(query) > -1 || item.surname.toLowerCase().indexOf(query) > -1
                || item.username.toLowerCase().indexOf(query) > -1 || item.email.toLowerCase().indexOf(query) > -1)
        });
        this.setState({query, filteredItems});
    };

    handleSearch = (e) => {
        e.preventDefault();
        console.log(e.key);
        if (e.key === 'Enter')
            return History.push('?search=' + this.state.query);
    };

    render() {
        const {params} = this.state;

        console.log('this', params.get('set'))
        // const lexicon = {};
        // const {name, wordsArray} = this.props.LexiconStore.list.get('zHkkQzZzXBvWvA2YJkWe') || {};
        // if (wordsArray && wordsArray.length) {
        //     wordsArray.forEach(i => {
        //         lexicon[i.turkish] = i.rate;
        //     })
        // }

        //
        // const tester = new LexiconTester();
        // tester.lexicon = lexicon;
        // console.log(tester.test('terketmek seni cok zor değil sevdiğim'));

        const {tweetsArray} = this.props.SetStore.list.get(params.get('set')) || {};
        const {lexicon} = this.props.LexiconStore.list.get(params.get('lexicon')) || {};
        const key = params.get('model') + '-' + params.get('set');
        const results = this.props.ModelStore.results.get(key) || {};
        console.log({results});
        const tester = new LexiconTester();
        tester.lexicon = lexicon;
        const data = [];
        const scores = {
            mpositives: 0,
            mnegatives: 0,
            mneutrals: 0,
            mpos: 0,
            mneg: 0,
            mnei: 0,
            apositives: 0,
            anegatives: 0,
            aneutrals: 0,
            apos: 0,
            aneg: 0,
            anei: 0,
            positives: 0,
            negatives: 0,
            neutrals: 0,
            pos: 0,
            neg: 0,
            nei: 0
        };

        if (tweetsArray) {
            const total = tweetsArray.length;
            tweetsArray.map(twit => {
                const afinn = tester.test(twit.text);
                const mscore = results[twit.id];
                const ascore = afinn.score > 0 ? 1 : afinn.score < 0 ? -1 : 0;
                const score = (mscore + ascore) > 0 ? 1 : (mscore + ascore) < 0 ? -1 : 0;

                if (twit.sentiment > 0 && twit.sentiment === mscore) {
                    scores.mpositives++;
                } else if (twit.sentiment < 0 && twit.sentiment === mscore) {
                    scores.mnegatives++;
                } else if (twit.sentiment === 0  && twit.sentiment === mscore) {
                    scores.mneutrals++;
                }

                if (twit.sentiment > 0 && twit.sentiment === ascore) {
                    scores.apositives++;
                } else if (twit.sentiment < 0 && twit.sentiment === ascore) {
                    scores.anegatives++;
                } else if (twit.sentiment === 0  && twit.sentiment === ascore) {
                    scores.aneutrals++;
                }

                if (twit.sentiment > 0 && twit.sentiment === score) {
                    scores.positives++;
                } else if (twit.sentiment < 0 && twit.sentiment === score) {
                    scores.negatives++;
                } else if (twit.sentiment === 0  && twit.sentiment === score) {
                    scores.neutrals++;
                }

                scores.mpos = scores.mpositives / total;
                scores.mneg = scores.mnegatives / total;
                scores.mneu = scores.mneutrals / total;

                scores.apos = scores.apositives / total;
                scores.aneg = scores.anegatives / total;
                scores.aneu = scores.aneutrals / total;

                scores.pos = scores.positives / total;
                scores.neg = scores.negatives / total;
                scores.neu = scores.neutrals / total;

                data.push({
                    name: twit.text,
                    sentiment: twit.sentiment,
                    AFINN: ascore,//afinn.score,
                    model: mscore,
                    // result: score === twit.sentiment ? 0 : -.5
                });
                return twit;
            });
        }

        // const {isAdmin, isEditor} = this.props.AuthStore.me;
        return (<React.Fragment>
                <div className="list">
                    <div className="searchInput text-right pr-4">
                        <Input type="text" className="form-control-sm" placeholder="Filter or Search..."
                               value={this.state.query}
                               onKeyUp={this.handleSearch} onChange={this.handleFilter}/>
                        {/*<small className="text-muted">Total result: {this.props.count}</small>*/}
                    </div>
                    <div className="row mt-5">

                        <LineChart
                            width={940}
                            height={300}
                            data={data}
                            margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis/>
                            <YAxis/>
                            <Tooltip/>
                            <Legend/>
                            <Line type="monotone" dataKey="sentiment" stroke="#8884d8" activeDot={{r: 8}}/>
                            <Line type="monotone" dataKey="AFINN" stroke="#82ca9d"/>
                            {/*<Line type="monotone" dataKey="result" stroke="#D95043"/>*/}
                        </LineChart>

                    </div>
                    <div className="text-center">
                        <strong>NB:</strong>
                        <span className="mx-2">positive: <span>{(scores.mpos * 100).toFixed(2)}</span></span>
                        <span className="mx-2">negative: <span>{(scores.mneg * 100).toFixed(2)}</span></span>
                        <span className="mx-2">neutrals: <span>{(scores.mneu * 100).toFixed(2)}</span></span>
                        <span
                            className="mx-2">total: <span>{((scores.mpos + scores.mneg + scores.mneu) * 100).toFixed(2)}</span></span>
                    </div>

                    <div className="text-center">
                      <strong>AFINN:</strong>
                        <span className="mx-2">positive: <span>{(scores.apos * 100).toFixed(2)}</span></span>
                        <span className="mx-2">negative: <span>{(scores.aneg * 100).toFixed(2)}</span></span>
                        <span className="mx-2">neutrals: <span>{(scores.aneu * 100).toFixed(2)}</span></span>
                        <span
                            className="mx-2">total: <span>{((scores.apos + scores.aneg + scores.aneu) * 100).toFixed(2)}</span></span>
                    </div>

                    <div className="text-center">
                      <strong>MIX:</strong>
                        <span className="mx-2">positive: <span>{(scores.pos * 100).toFixed(2)}</span></span>
                        <span className="mx-2">negative: <span>{(scores.neg * 100).toFixed(2)}</span></span>
                        <span className="mx-2">neutrals: <span>{(scores.neu * 100).toFixed(2)}</span></span>
                        <span
                            className="mx-2">total: <span>{((scores.pos + scores.neg + scores.neu) * 100).toFixed(2)}</span></span>
                    </div>
                    {/*{this.props.count &&*/}
                    {/*<Pagination page={this.state.page} count={this.props.count}*/}
                    {/*            limit={this.state.limit} range={this.state.range}*/}
                    {/*            query={{search: this.props.search}}/>}*/}
                </div>
            </React.Fragment>
        );
    }
}

export default Test
