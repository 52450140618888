import {autorun, toJS} from 'mobx';
import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {CSVLink} from "react-csv";
import {LexiconTester} from "../../helpers/LexiconTester";
import {Tweet} from "./Tweet";

@inject('AuthStore', 'UserStore', 'LexiconStore', 'ModelStore')
@observer
class List extends Component {

  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    const search = params.get('search') || '';
    this.state = {
      query: search,
      loading: false
    };

    autorun(() => {
      const me = props.AuthStore.me;
      props.UserStore.userTweets(me.uid);
    })
  }

  handleFilter = (e) => {
    e.preventDefault();
    const query = e.currentTarget.value.toLowerCase();
    this.setState({query});
  };

  doAnalyze = async () => {
    this.setState({loading: true})
    const list = this.props.UserStore.tweets;
    if (!list) return null;
    await this.props.ModelStore.analyze('5665673409724416', [...list.values()])
    this.setState({loading: false})
  }

  render() {
    const me = this.props.AuthStore.me;
    const spreads = this.props.UserStore.spreads || [];
    const importants = this.props.UserStore.importants || [];
    const list = this.props.UserStore.tweets;
    if (!list) return null;
    const tweetsArray = [...list.values()];
    const results = toJS(this.props.ModelStore.results.get('5665673409724416')) || false;
    const csvData = []
    const sentiment = {'-1': 'negative', '0': 'neutral', '1': 'positive'}

    const tester = new LexiconTester();
    const {lexicon} = this.props.LexiconStore.list.get('zHkkQzZzXBvWvA2YJkWe') || {};
    tester.lexicon = lexicon;

    const data = [];
    const scores = {
      mpositives: 0,
      mnegatives: 0,
      mneutrals: 0,
      mpos: 0,
      mneg: 0,
      mnei: 0,
      apositives: 0,
      anegatives: 0,
      aneutrals: 0,
      apos: 0,
      aneg: 0,
      anei: 0,
      positives: 0,
      negatives: 0,
      neutrals: 0,
      pos: 0,
      neg: 0,
      nei: 0
    };

    if (results) {
      const total = tweetsArray.length;
      tweetsArray.map(twit => {
        const afinn = tester.test(twit.text);
        const mscore = results[twit.id];
        const ascore = afinn.score > 0 ? 1 : afinn.score < 0 ? -1 : 0;
        const score = (mscore || ascore) > 0 ? 1 : (mscore || ascore) < 0 ? -1 : 0;

        if (mscore > 0) {
          scores.mpositives++;
        } else if (mscore < 0) {
          scores.mnegatives++;
        } else if (mscore === 0) {
          scores.mneutrals++;
        }

        if (ascore > 0) {
          scores.apositives++;
        } else if (ascore < 0) {
          scores.anegatives++;
        } else if (ascore === 0) {
          scores.aneutrals++;
        }

        if (score > 0) {
          scores.positives++;
        } else if (score < 0) {
          scores.negatives++;
        } else if (score === 0) {
          scores.neutrals++;
        }

        scores.mpos = scores.mpositives / total;
        scores.mneg = scores.mnegatives / total;
        scores.mneu = scores.mneutrals / total;

        scores.apos = scores.apositives / total;
        scores.aneg = scores.anegatives / total;
        scores.aneu = scores.aneutrals / total;

        scores.pos = scores.positives / total;
        scores.neg = scores.negatives / total;
        scores.neu = scores.neutrals / total;

        data.push({
          name: twit.text,
          sentiment: twit.sentiment,
          AFINN: afinn.score,
          model: mscore,
          result: score === twit.sentiment ? 6 : -6
        });

        csvData.push([twit.text, sentiment[String(mscore)]])

        return twit;
      });
    }

    const status = scores.mpos > scores.mneg
      ? scores.mpos > scores.mneu
        ? 'positive'
        : 'neutral'
      : scores.mneg > scores.mneu
          ? 'negative'
          : 'neutral'

    return (<React.Fragment>
        {!results
          ? <button className='btn btn-primary' onClick={this.doAnalyze}>
            {this.state.loading && <span><i className="fa fa-circle-notch fa-spin"/>&nbsp;</span>}
            SHOW MY ANALYZE
          </button>
          : <div className="list">
            <div className="d-flex mt-5 border-bottom pb-3 mb-3">
              <div className="flex-grow-1">
                <p>Hi <strong>{me.first};<br/>

                </strong> We collect <strong>{tweetsArray.length} </strong>
                  tweets related with your keywords (<i>{me.keywords.join(', ')}</i>) for today.
                  <br/> Your tweets mostly <strong className={status}>{status}</strong> and
                  <span className="positive"> {(scores.mpos * 100).toFixed(2)}% postive</span>,
                  <span className="negative"> {(scores.mneg * 100).toFixed(2)}% negative</span>,
                  <span className="neutral"> {(scores.mneu * 100).toFixed(2)}% neutral</span>.</p>
                <p>
                  <CSVLink data={csvData}
                           filename={"report.csv"}>Click here </CSVLink>
                  to download your excel report</p>
              </div>
            </div>
            <div className="row" style={{height: 400}}>
              <div className="col-6 small d-flex flex-column h-100">
                <h5>TOP-10 Most Important</h5>
                <div className="flex-grow-1 overflow-auto pr-3">
                  {importants.map(i => <Tweet key={'important-' + i.id} id={i.id}
                                              data={{...i, analyze: results[i.id]}}/>)}
                </div>
              </div>
              <div className="col-6 small d-flex flex-column h-100">
                <h5>TOP-10 Most Spread</h5>
                <div className="flex-grow-1 overflow-auto pr-3">
                  {spreads.map(i => <Tweet key={'spread-' + i.id} id={i.id} data={{...i, analyze: results[i.id]}}/>)}
                </div>
              </div>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

export default List
