import {action, computed, observable, runInAction} from 'mobx';
import firebase from 'firebase/app';
import {services as Services} from '../services';
import {ModelModel} from '../models/ModelModel';

export default class ModelStore {
    @observable isReady = false;
    dbListener = null;
    @observable _list = new observable.map();
    //@observable list = [];
    @observable current = null;

    @observable data = null;
    @observable status = "initial";
    @observable searchQuery = "";

    @observable _undos = [];
    @observable ctrZEvent = null;

    @observable _scores = [0, 0, 0];
    @observable results = new observable.map();
    @observable _list = new observable.map();

    constructor(Stores) {
        this.stores = Stores;
        this.service = Services.Model;
        this.model = ModelModel;
    }

    get list() {
        if (!this.dbListener) {
            this.listenToDB();
        }
        return this._list;
    }

    @computed
    set list(item) {
        return this._list.set(item);
    }

    train = async (data) => {
        return await this.service.train(data);
        // const item = new this.model(data);
        // return await item.save();
    };

  @action
  test = async (model, set) => {
    const key = model + '-' + set;
    if (!this.results.get(key)) {
      console.log('test', key, this.results);
      this.results.set(key, await this.service.test(model, set).then(r => r.results));
    }
    return this.results.get(key);
    // const item = new this.model(data);
    // return await item.save();
  };

  @action
  analyze = async (model, tweets) => {
    const key = model;
    if (!this.results.get(key)) {
      this.results.set(key,await this.service.analyze(model, tweets).then(r => r.results));
    }
    // const key = model + '-' + set;
    // if (!this.results.get(key)) {
    //   console.log('test', key, this.results);
    //   this.results.set(key, await this.service.test(model, set).then(r => r.results));
    // }
    // return this.results.get(key);
    // const item = new this.model(data);
    // return await item.save();
  };

    create = async (data) => {
        const item = new this.model(data);
        return await item.save();
    };

    listenToDB = () => {
        this.dbListener = firebase
            .firestore()
            .collection('models')
            //.orderBy('createdAt', 'asc')
            .onSnapshot(snapshot => {
                runInAction(() => {
                    this.status = 'listening';
                    this.isReady = true;
                    snapshot.docChanges().forEach(change => {
                        const doc = change.doc.data(); // TODO add words filter
                        doc.id = change.doc.id;
                        if (change.type === 'added' || change.type === 'modified') {
                            this.handleAdd(doc);
                        }
                        if (change.type === 'removed') {
                            this.handleRemove(doc.id);
                        }
                    });
                });
            });
    };

    @action
    handleRemove(docId) {
        this._list.delete(docId);
    }

    @action
    handleAdd(doc) {
        const item = new this.model(doc);
        if (this._list.has(doc.id)) {
            if (JSON.stringify(item) !== JSON.stringify(this._list.get(doc.id)))
                this._list.set(doc.id, item);
        } else
            this._list.set(doc.id, item);
    }

    pushUndo = (item) => {
        this._undos.push(item);
        console.log('set stores undo', this.ctrZEvent, item);
        if (!this.ctrZEvent)
            this.ctrZEvent = document.addEventListener('keydown', async e => {
                if (e.key === 'z' && (e.metaKey || e.ctrlKey) && !this.undoing) {
                    const item = this._undos.pop();
                    console.log('UNDO', {item});
                    if (item && item.hasOwnProperty('undo')) {
                        this.undoing = true;
                        await item.undo();
                        this.undoing = false;
                    }
                }
                console.log({e});
            });
    }
}
