export const config = {
  apiKey: "AIzaSyCwBuIXabYpDHitc8yx0ME_dItjD9qvzI0",
  authDomain: "sentimenttr.firebaseapp.com",
  databaseURL: "https://sentimenttr.firebaseio.com",
  projectId: "sentimenttr",
  storageBucket: "sentimenttr.appspot.com",
  messagingSenderId: "399802156835",
  appId: "1:399802156835:web:f679245ae0bfeb06e42e96",
  measurementId: "G-SK6VTJCRT9"
};
