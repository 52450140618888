import React from "react";
import {Date} from "../../components";


export const Tweet = (props) => {
  const {data} = props
  const colors = {'null': 'transparent', '-1': '#D95043', '0': '#666', '1': '#3FCFDA'}
  // eslint-disable-next-line react/react-in-jsx-scope
  return <div className="tweet border-bottom mb-2 pb-2">
    <div className="pl-2" style={{borderLeft: '5px solid transparent', borderColor: colors[String(data.analyze)]}}>
      <p>{data.text}</p>
    <small className="d-block">
      <a href={'https://twitter.com/' + data.username} target="_blank"
         rel="noopener noreferrer">{data.name}</a> - <a
      href={'https://twitter.com/' + data.username + '/status/' + data.id}
      target="_blank"
      rel="noopener noreferrer"><Date date={data.createdAt}/></a>
      <div>
        followers: {data.followers}{' - '}
        friends: {data.friends}{' - '}
        favorite: {data.favorite}{' - '}
        verified: {String(data.verified)}{' - '}
        retweeted: {String(data.retweet)}{' - '}
      </div>
    </small>
    </div>
  </div>
}
