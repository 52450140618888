import {autorun} from 'mobx';
import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {Date, Loader, Sentimentor} from "../../components";


@inject('AuthStore', 'UserStore')
@observer
class List extends Component {

  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    const search = params.get('search') || '';
    this.state = {
      query: search,
    };

    autorun(() => {
      const me = props.AuthStore.me;
      props.UserStore.userTweets(me.uid);
    })
  }

  handleFilter = (e) => {
    e.preventDefault();
    const query = e.currentTarget.value.toLowerCase();
    this.setState({query});
  };

  render() {
    const list = this.props.UserStore.tweets;
    const data = [...list.values()];
    return (<React.Fragment>
        <div className="list">
          {data.length ? <table className="table">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Tweet</th>
            </tr>
            </thead>
            <tbody>
            {data.map((item, i) => {
              if (item.isRemoved) return null;
              const data = <div>
                <p>{item.text}</p>
                <small className="d-block">
                  <a href={'https://twitter.com/' + item.username} target="_blank"
                     rel="noopener noreferrer">{item.name}</a> - <a
                  href={'https://twitter.com/' + item.username + '/status/' + item.id}
                  target="_blank"
                  rel="noopener noreferrer"><Date date={item.createdAt}/></a>
                  <div>
                    followers: {item.followers}{' - '}
                    friends: {item.friends}{' - '}
                    favorite: {item.favorite}{' - '}
                    verified: {String(item.verified)}{' - '}
                    retweeted: {String(item.retweet)}{' - '}
                  </div>
                </small>
              </div>;

              return (<tr key={'twit' + item.id}>
                <th scope="row">{i + 1}</th>
                <td>{data}</td>
              </tr>);

            })}
            </tbody>
          </table> : ''}
        </div>
      </React.Fragment>
    );
  }
}

export default List
