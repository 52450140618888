import {inject, observer} from "mobx-react";
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

@inject('AuthStore', 'UserStore')
@observer
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    static renderHello(t, user) {
        if (user) {
            return <p>{t('hello')} <strong> {user} </strong>, <br/>
                you are logged in!</p>
        }
    }

    static renderGoker(t, user) {
        if (user) {
            return <div>
                <p>{t('hello')} my KING, </p>
                <p>welcome to back your awesome project the sentimenttr!</p>
                <p>sentimenttr is gonna be better with you! <i>develop me, code me, deploy me please!</i></p>
                <p>
                    lets go to <Link to="/tests">tests</Link>
                </p>
            </div>
        }
    }

    static renderDamla(t, user) {
        if (user) {
            return <div>
                <p>{t('hello')} <strong> {user} </strong> teacher, </p>
                <p>welcome to back your awesome project the sentimenttr!</p>
                <p>setimenttr is gonna be better with you! <i>teach me, teach me, teach me please =)</i></p>
                <p>
                    lets go to <Link to="/lexicons">Lexicons</Link>
                </p>
            </div>
        }
    }

    render() {
        const {t} = this.props;
        const {me} = this.props.AuthStore;
        return <React.Fragment>
            <section className="container">
                {me.first === 'Damla'
                    ? <div>{me && Home.renderDamla(t, me.first)}</div>
                    : me.first === 'Goker'
                        ? <div>{me && Home.renderGoker(t, me.first)}</div>
                        : <div>{me && Home.renderHello(t, me.name)}</div>
                }
                {!me.isWorker && !me.isManager && !me.isEditor && !me.isAdmin
                    ? <div>
                        <p>You don't have any keywords. Please add some keywords for track.</p>
                        <Link to='/keywords'>Add Keywords</Link>
                    </div>
                    : ''}
            </section>
        </React.Fragment>;
    }
}

export default withTranslation("Home")(Home);
