import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {Input} from "reactstrap";

import DataTable from 'react-data-table-component';
import {Sentimentor} from '../../components';


@inject('AuthStore', 'TweetStore', 'SetStore')
@observer
class List extends Component {

    constructor(props) {
        super(props);
        const params = new URLSearchParams(props.location.search);
        const search = params.get('search') || '';
        const page = params.get('page') || 1;
        this.state = {
            me: props.me,
            count: 0,
            page,
            limit: 10,
            range: 10,
            items: [],
            query: search,
            search: search,
            filteredItems: false,
            system: false,

            id: props.match.params.id
        };

        // autorun(() => {
        //
        //     const {name, tweets} = this.props.SetStore.list.get(this.state.id) || {};
        // })
    }

    componentDidMount() {
        // console.log('componentDidMount', this.state);
        // const {getUsers} = this.props.UserStore;
        // if (this.state.query) {
        //   const filter = {skip: Math.max(0, this.state.page - 1) * this.state.limit, limit: this.state.limit};
        //   getUsers({query: this.state.query, filter});
        // } else {
        //   getUsers();
        // }
    }

    componentDidUpdate(props) {
        // const params = new URLSearchParams(props.location.search);
        // const search = params.get('search') || '';
        // const page = params.get('page') || 1;
        // console.log('componentDidUpdate', props);
        //
        // if (page !== this.state.page) {
        //   console.log('page', props);
        //   const filter = {skip: Math.max(0, page - 1) * this.state.limit, limit: this.state.limit};
        //   this.setState({page: page});
        //   this.props.fetchList({query: this.state.query, filter});
        // } else if (search !== this.state.search) {
        //   console.log('search', props);
        //   this.setState({search});
        //   this.props.fetchList({query: search});
        // }
        //
        // if (props.data && props.data.items) {
        //   this.setState({
        //     isLoading: false,
        //     count: props.data.count,
        //     items: props.data.items,
        //     filteredItems: false
        //   });
        // }
    }

    handleFilter = (e) => {
        e.preventDefault();
        console.log(e.currentTarget.value);
        const query = e.currentTarget.value.toLowerCase();
        const filteredItems = this.state.items.filter(item => {
            console.log({item});
            return (item.name.toLowerCase().indexOf(query) > -1 || item.surname.toLowerCase().indexOf(query) > -1
                || item.username.toLowerCase().indexOf(query) > -1 || item.email.toLowerCase().indexOf(query) > -1)
        });
        this.setState({query, filteredItems});
    };

    handleSearch = (e) => {
        e.preventDefault();
        console.log(e.key);
        if (e.key === 'Enter')
            return History.push('?search=' + this.state.query);
    };

    checkInSet = (item) => {
        const {tweets} = this.props.SetStore.list.get('VznJRVLOvMBckjcTmP6v') || {};
        //const {status, list} = this.props.TweetStore;

        if (typeof item === 'object' && tweets && tweets.hasOwnProperty(item.id)) {
            console.log(item.id, tweets[item.id]);
            return tweets[item.id]['sentiment'];
        } else return null;
    };

    columns = [
        {
            name: 'Text',
            selector: 'text',
            sortable: true,
            grow: 3,
            wrap: true,
            //cell: row => <div>{row.genres.map((genre, i) => <div key={i}>{genre}</div>)}</div>,
            //cell: row => <Link to={'/lexicons/' + row.id}>{row.name}</Link>,
        },
        {
            name: 'Sentiment',
            selector: 'sentiment',
            sortable: true,
            cell: row => <Sentimentor item={row} name="sentiment" value={row.sentiment}/>,
            right: true,
        },
    ];

    render() {
        // const {isAdmin, isEditor} = this.props.AuthStore.me;
        const {tweetsArray} = this.props.SetStore.list.get(this.state.id) || {};
        return (<React.Fragment>
                <div className="list">
                    <div className="searchInput text-right pr-4">
                        <Input type="text" className="form-control-sm" placeholder="Filter or Search..."
                               value={this.state.query}
                               onKeyUp={this.handleSearch} onChange={this.handleFilter}/>
                        {/*<small className="text-muted">Total result: {this.props.count}</small>*/}
                    </div>
                    <div className="row">
                        {tweetsArray &&
                        <DataTable
                            noHeader={true}
                            columns={this.columns}
                            data={tweetsArray}
                        />}
                        {/*{this.renderItems()}*/}
                    </div>
                    {/*{this.props.count &&*/}
                    {/*<Pagination page={this.state.page} count={this.props.count}*/}
                    {/*            limit={this.state.limit} range={this.state.range}*/}
                    {/*            query={{search: this.props.search}}/>}*/}
                </div>
            </React.Fragment>
        );
    }
}

//
// const mapStateToProps = (state) => {
//   return {system: state.system, me: state.auth.me}
// };

export default List//connect(mapStateToProps, {fetchList})(List);
