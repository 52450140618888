import {action, computed, observable, runInAction} from 'mobx';
import firebase from 'firebase/app';
import TwitterService from '../services/TwitterService';
import {TweetModel} from '../models/TweetModel';

export default class TweetStore {
  @observable isReady = false;
  dbListener = null;
  @observable _list = observable.map();
  @observable searchList = observable.map();
  //@observable list = [];
  @observable user = null;

  @observable data = null;
  @observable status = "initial";
  @observable searchQuery = "";

  @observable _undos = [];
  @observable ctrZEvent = null;

  constructor(Stores) {
    this.stores = Stores;
    this.service = new TwitterService();
    this.model = TweetModel;
  }

  get list() {
    if (!this.dbListener) {
      this.listenToDB();
    }
    console.log('list user', this.dbListener, this._list);
    return this._list;
  }

  @computed
  set list(item) {
    return this._list.set(item);
  }

  listenToDB = agencyId => {
    this.dbListener = firebase
      .firestore()
      .collection('tweets')
      //.orderBy('createdAt', 'asc')
      .onSnapshot(snapshot => {
        runInAction(() => {
          this.isReady = true;
          snapshot.docChanges().forEach(change => {
            const doc = change.doc.data(); // TODO add words filter
            doc.id = change.doc.id;
            if (change.type === 'added' || change.type === 'modified') {
              this.handleAdd(doc);
            }
            if (change.type === 'removed') {
              this.handleRemove(doc.id);
            }
          });
        });
      });
  };

  @action
  handleRemove(docId) {
    this._list.delete(docId);
  }

  @action
  handleAdd(doc) {
    const item = new this.model(doc);
    if (this._list.has(doc.id)) {
      if (JSON.stringify(item) !== JSON.stringify(this._list.get(doc.id)))
        this._list.set(doc.id, item);
    } else
      this._list.set(doc.id, item);
  }

  @action
  search = async (q) => {
    if (this.searchQuery === q) return;
    this.searchQuery = q;
    this.searchList = observable.map();
    const list = await this.service.get(q);
    for (const id in list) {
      if (!list.hasOwnProperty(id)) continue;
      const item = new this.model(list[id]);
      this.searchList.set(id, item);
    }
  };


  searchInApi = () => {
    this.service.get();
  };

  pushUndo = (item) => {
    this._undos.push(item);
    if (!this.ctrZEvent)
      this.ctrZEvent = document.addEventListener('keydown', async e => {
        if (e.key === 'z' && (e.metaKey || e.ctrlKey) && !this.undoing) {
          const item = this._undos.pop();
          console.log('UNDO', {item})
          if (item && item.hasOwnProperty('undo')) {
            this.undoing = true;
            await item.undo();
            this.undoing = false;
          }
        }
        console.log({e});
      });
  }

}
