export class ModelService {

  constructor(request) {
    this.request = request
  }

  train = async (data) => {
    console.log("ModelService train")
    return await this.request.post('/models', data);
  };

  test = async (id, set) => {
    return await this.request.put('/models/' + id, {sets: [set]});
  };

  analyze = async (id, tweets) => {
    return await this.request.post('/analyze/' + id, {tweets});
  };

}
